import "./App.css";

function App() {
  return (
    <div className="Container">
      <h2 className="TitleText">This domain's up for sale.</h2>
      <h3 className="Body">Interested?</h3>
      <button className="button-19" onClick={(e) => {window.location.href ='mailto:admin@embry.io';}}>Get in touch</button>
    </div>
  );
}

export default App;
